import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function Iot({ data }) {
  let i = 0
  return [
    <section className="Iot main" key={i++}>
      <div className="titleImage">
        <div className="titleImage__bg"></div>
      </div>
      <div className="titleArtboard">
        <div className="titleArtboard__bg"></div>
      </div>
      <div className="transformBlock main"></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-6" style={{ height: "100%" }}>
            <div className="titleBlock" id="iot-titleBlock">
              <div className="titleBlock_logo">
                <img src={data.images.logo} alt="Iot logotype" />
              </div>
              <div className="titleBlock_text">
                <h1>{data.title}</h1>
                <span>{data.subtitle}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>,

    <OverviewComponent {...data} key={i++} />,

    <section className="solutionImgBg" key={i++}>
      <div className="transformBlock solution right"></div>
      <div className="image">
        <img src={data.images.picture1} alt="Iot application" />
      </div>
    </section>,

    <section className="solution first" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.solution.title}</h2>
            {data.solution.textFirst.map((item, i) => (
              <p className="mb-4" key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,

    <section className="solutionImgBg" key={i++}>
      <div
        className="image fixed"
        style={{ backgroundImage: `url(${data.images.bgPicture2})` }}
      >
        <img src={data.images.picture2} alt="Iot application" />
      </div>
    </section>,

    <section className="solution" key={i++} id="iot-solution">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            {data.solution.textLast.map((item, i) => (
              <p className="mb-4" key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,

    <section className="solutionImgBg" key={i++}>
      <div className="transformBlock solution left"></div>
      <div className="image">
        <img src={data.images.picture3} alt="Iot application" />
      </div>
    </section>,

    <section className="solution last" key={i++} style={{ margin: "22px 0" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.delivered.title}</h2>
            <p>{data.delivered.text}</p>
          </div>
        </div>
      </div>
    </section>,
  ]
}
