import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Iot from "../../components/SingleCase/Iot"
import ContactBtnBlock from "../../components/SingleCase/ContactBtnBlock"
import Navigate from "../../components/SingleCase/Navigate"
import RelatedProjects from "../../components/SingleCase/RelatedProjects"
import dataForIot from "../../information/Cases/dataForIot"
import { mainUrl } from "../../js/config"
import "../../components/SingleCase/PortfolioComponent.scss"

export default class IotPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }
  render() {
    const {
      classComponent,
      nextLink,
      prevLink,
      relatedProjects,
      data,
    } = dataForIot

    return (
      <section className="singleProject Iot">
        <SEO
          title="Internet of Things Development Case — OS-System"
          description='Internet of Things App Development - Internet of Thing - how to create an application for monitor operation and identify equipment anomalies'
          canonical={`${mainUrl}/cases/iot/`}
        />
        <Layout showFormPoint={100}>
          <Iot data={data} />
          <Navigate
            classComponent={classComponent}
            nextLink={nextLink}
            prevLink={prevLink}
          />
          <ContactBtnBlock />
          <RelatedProjects relatedProjects={relatedProjects} />
        </Layout>
      </section>
    )
  }
}
