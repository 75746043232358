import {
  Artboard,
  BgPicture,
  Imac,
  Logo,
  Picture1,
  Picture2,
  Picture3,
  Triangle1,
  Triangle2,
  Triangle3,
} from "../../images/SingleCase/Iot"

const dataForIot = {
  type: "project",
  smart: true,
  classComponent: "Iot",
  nextLink: "cases/gud-job/",
  prevLink: "cases/karpatska-chaika/",
  data: {
    title: "Internet of Things",
    subtitle:
      "The application allows comparing ongoing equipment performance to its standard performance to identify anomalies",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "The client is US-based startup focused on industrial Internet of Things solutions with seasoned IT management team.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "Industrial manufacturers are facing extra maintenance, service and lost production costs when heavy machinery unexpectedly stops. Primary business concerns are related to reduced costs of operations through reduced maintenance costs as well as improved reliability of operations to allow for more robust supply chain.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "ReactJS",
            "Redux",
            "Node.js",
            "AWS (IAM",
            "Cognito",
            "Lambda",
            "API Gateway",
            "CloudFormation",
            "CloudWatch",
            "AWS IoT",
            "DynamoDB",
            "ElasticSearch",
            "Firehose",
            "Kinesis",
            "Greengrass",
            "S3",
            "EC2",
            "SNS",
            "SES)",
            "Kibana",
            "Skedler",
            "PhantomJS",
            "Canvas.js",
            "Serverless",
            "Travis CI/CD",
            "Zephyr",
            "Allure",
            "TestNG",
            "Selenium",
            "Java Core",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project manager",
              count: 1,
            },
            {
              type: "Database Developer",
              count: 1,
            },
            {
              type: "DevOps",
              count: 1,
            },
            {
              type: "React Developers",
              count: 6,
            },
            {
              type: "QA Engineers",
              count: 3,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "months",
              count: 18,
            },
          ],
        },
        {
          type: "Client",
          content: ["Startup"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "The regular alternatives for such pain include increasing maintenance frequency, outsourcing it or improving process quality to prevent equipment failure. The rapid development of Internet of Things allowed practicing Predictive maintenance that should identify abnormal machine behaviour prior to any incident.",
        "Our Customer was a startup that decided to implement such approach for specific B2B market that would. The solution was developed in close cooperation with Customer’s Product Owner.",
        "In a nutshell, the solution presented a SmartSensor developed by external team that connected to industrial equipment and passed generated data metrics to the cloud. The data would continuously get evaluated and the Alert would be raised against any anomalies and sent to engineer’s mobile phone or email to decide whether the anomaly is worth checking upon to prevent	equipment failure.",
        "Additionally, either an engineer or his supervisor can decide to receive scheduled or ad- hoc reports on single or batch equipment activity in order to include them in the overall maintenance or operations reports.",
        "Apart from the Rules and Reports engine, we implemented a set of Canvas.js and Kibana Dashboards that allow monitoring raw data, equipment status, and derived metrics in the real time.",
      ],
      textLast: [
        "We chose Scrum for better transparency and accountability of the delivered product parts. We started with developing simple prototype in Adobe Illustrator for wireframing purposes. Having validated the prototype with end users, we moved to the implementation of basic HTML version of the app without any functionality behind it for further validation.",
        "Based on the obtained feedback we sketched an MVP that included basic Dashboards, Rules, Notifications, and Reports - with the simple data flow from the Sensor to the AWS Cloud server.",
        "Being a startup company, the Customer had to continuously pitch its product to prospects, that required OSS to adapt development priorities based on short-term business needs. Thanks to Scrum that was possible without disrupting the deployment pipeline. It was a long shot but we were making a bet on UX improvement to make the industrial B2B solution stand out against its formal-looking competitors.",
        "After the MVP, we recognized the requirement for cost optimization and focused on the existing AWS architecture improvement. We automated the release process from Continuous Integration to the Continuous Deployment through setting up separate development environments for each new feature, achieving full unit and automated test coverage as well as making sure that the team had complete dedication to the output quality.",
      ],
    },
    delivered: {
      title: "Value delivered",
      text:
        "Installed the solution at 2 major clients - to monitor day-to-day operations and detecting hardware anomalies as well as allow for improved quality control at the innovative assembly line.",
    },
    images: {
      logo: Logo,
      imac: Imac,
      artboard: Artboard,
      picture1: Picture1,
      picture2: Picture2,
      picture3: Picture3,
      bgPicture2: BgPicture,
      triangle1: Triangle1,
      triangle2: Triangle2,
      triangle3: Triangle3,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/cartovera",
      id: "cartovera",
      title: "Cartovera",
    },
    {
      link: "/cases/service-doc",
      id: "service-doc",
      title: "Service-doc",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/iot",
}

export default dataForIot
